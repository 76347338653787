/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef, useMemo, forwardRef } from 'react';

import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension';
import {
	isModifierValid,
	replaceRegex,
} from '@rs-ui/views/OrganizationDetailView/organizationDetailSections/ris/StudySets/modifierUtils';

const ModifiersStyles = {
	stackStyle: {
		width: '238px',
		height: '30px',
		background: '#414141',
		border: '1px solid rgba(255, 255, 255, 0.2)',
		borderRadius: '15px',
	},
};

const ModifierStyles = {
	stackStyle: {
		width: '54px',
		height: '24px',
		background: 'rgba(255, 255, 255, 0.2)',
		border: '1px solid rgba(255, 255, 255, 0.2)',
		borderRadius: '41px',
	},
	typographyStyles: {
		font: 'Roboto',
		fontWeight: 400,
		fontSize: '14px',
		color: 'rgba(255, 255, 255, 0.8)',
		lineHeight: '16px',
		letterSpacing: '0.25px',
		height: '16px',
	},
	iconStyle: { color: 'rgba(255, 255, 255, 0.6)', fontSize: 17 },
};

const REASON = 'Modifier';

const TwoCharInput = forwardRef(({ value, onChange, onDelete, readOnly, notValidModifier }, ref) => {
	const handleChange = e => {
		const upperCaseValue = e.target.value.toUpperCase();
		const regexValue = upperCaseValue.replace(replaceRegex, '');
		onChange(regexValue);
	};

	return (
		<Stack
			alignItems="center"
			direction="row"
			justifyContent="center"
			spacing="0.5"
			sx={{ ...ModifierStyles.stackStyle, border: notValidModifier && '2px solid red' }}
		>
			{readOnly ? (
				<Typography
					data-testid="Typography"
					sx={{
						width: '20px',
						...ModifierStyles.typographyStyles,
					}}
				>
					{value.substring(0, 2)}
				</Typography>
			) : (
				<TextField
					inputProps={{
						maxLength: 2,
						style: {
							padding: '0px !important',
							width: '24px',
							...ModifierStyles.typographyStyles,
						},
					}}
					inputRef={ref}
					value={value}
					variant="standard"
					onChange={handleChange}
				/>
			)}

			{!readOnly && <CancelIcon sx={{ ...ModifierStyles.iconStyle }} onClick={onDelete} />}
		</Stack>
	);
});

const Modifier = ({ Modifiers, readOnly, onChange, onError }) => {
	const parentRef = useRef(null);
	const [inputs, setInputs] = useState(['']);
	const [numChild, setNumChild] = useState(0);
	const inputRefs = useRef([]);
	const inputIndex = useRef(false);

	useEffect(() => {
		const array = [''];
		if (!Modifiers) {
			setInputs(['']);
			return;
		}

		if (Array.isArray(Modifiers.extension)) {
			Modifiers =
				Modifiers.extension.find(item => item.url === fhirExtensionUrls.procedureCode.modifier)?.valueString ??
				'';
		}

		const values = Modifiers.split(',').slice(0, 4);
		let num = 0;

		values.forEach((value, index) => {
			if (value || !readOnly) {
				num += 1;
				array[index] = value;
			}
		});

		setInputs(array);
		setNumChild(num);
	}, [Modifiers, readOnly]);

	useEffect(() => {
		if (parentRef.current) {
			let totalWidth = 0;
			const { children } = parentRef.current;
			for (const _ of children) {
				totalWidth += 55;
			}

			if (totalWidth > 238) {
				parentRef.current.style.width = `100%`;
			} else {
				parentRef.current.style.width = `${totalWidth}px`;
			}
		}
	}, [numChild, readOnly]);

	useEffect(() => {
		// Reset the ref array whenever the inputs array changes
		inputRefs.current = inputRefs.current.slice(0, inputs.length);
		if (inputIndex.current) {
			inputRefs.current[inputRefs.current.length - 1]?.focus();
			inputIndex.current = false;
		}
	}, [inputs]);

	const hasNonEmptyString = useMemo(() => inputs.some(str => str.length !== 0), [inputs]);

	const handleInputChange = (index, value) => {
		const updatedInputs = [...inputs];
		updatedInputs[index] = value;

		if (isModifierValid(updatedInputs[index]) && updatedInputs.length <= 4) {
			updatedInputs.push('');
			if (onChange) {
				onChange(updatedInputs.join(), REASON);
			}

			inputIndex.current = true;
		}

		setInputs(updatedInputs);
	};

	const handleInputDelete = index => {
		const updatedInputs = [...inputs];

		if (updatedInputs.length > 0) {
			updatedInputs.splice(index, 1);
		}

		if (updatedInputs.length < 1 || updatedInputs.filter(str => str !== '').length === updatedInputs.length) {
			updatedInputs.push('');
		}

		setInputs(updatedInputs);
		inputIndex.current = true;

		if (onChange) {
			onChange(updatedInputs.join(), REASON);
		}
	};

	return (
		<>
			{hasNonEmptyString || !readOnly ? (
				<Stack
					ref={parentRef}
					alignItems="center"
					direction="row"
					justifyContent="center"
					spacing="1.5"
					sx={{ ...ModifiersStyles.stackStyle }}
				>
					{inputs.map((input, index) => {
						if (readOnly && !input) {
							return null;
						}

						return (
							<TwoCharInput
								key={index}
								ref={el => (inputRefs.current[index] = el)}
								index={index}
								notValidModifier={!isModifierValid(input) && input}
								readOnly={readOnly}
								value={input}
								onChange={value => handleInputChange(index, value)}
								onDelete={() => handleInputDelete(index)}
							/>
						);
					})}
				</Stack>
			) : (
				<Stack sx={{ height: '17px' }} />
			)}
		</>
	);
};

export default Modifier;
