import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SideBarLinks, TermsModalContent } from '../../PrivacyPolicy/TermsModal';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Icon from '@mui/material/Icon';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { useTranslation } from 'react-i18next';

const MobileTermsDrawerSidebar = ({ onClick }) => {
	const { t } = useTranslation('term');
	return (
		<Box>
			<Box sx={{ textAlign: 'center' }}>
				<Icon
					component={ArticleOutlinedIcon}
					sx={{
						color: 'rsPrimary.main',
					}}
				/>
			</Box>

			<Typography
				sx={{
					fontSize: '24px',
					lineHeight: '28px',
					color: '#121212',
					opacity: '0.87',
					textAlign: 'center',
				}}
			>
				{t('Terms of Service')}
			</Typography>

			<SideBarLinks onClick={onClick} />
		</Box>
	);
};

const MobileTermsDrawerContent = () => (
	<Box
		sx={{
			padding: '0 14px',
			boxSizing: 'border-box',
		}}
	>
		<TermsModalContent />
	</Box>
);

const MobileTermsDrawer = ({ setMobileDrawerProps }) => {
	const [isShownContent, setIsShownContent] = useState(false);
	const { t } = useTranslation('term');

	return (
		<Box>
			{isShownContent ? (
				<MobileTermsDrawerContent />
			) : (
				<MobileTermsDrawerSidebar
					onClick={() => {
						setIsShownContent(true);

						setMobileDrawerProps({
							stickyContentTop: (
								<Typography
									sx={{
										position: 'sticky',
										top: '0',
										fontWeight: '500',
										fontSize: '14px',
										lineHeight: '16px',
										letterSpacing: '0.1em',
										fontVariant: 'small-caps',
										color: '#666666',
										marginBottom: '14px',
										display: 'flex',
										alignItems: 'center',
										marginLeft: '8px',
									}}
									onClick={() => {
										setIsShownContent(false);
										setMobileDrawerProps({});
									}}
								>
									<Icon component={ChevronLeftOutlinedIcon} sx={{ marginRight: '8px' }} />
									{t('GO BACK')}
								</Typography>
							),
						});
					}}
				/>
			)}
		</Box>
	);
};

export default MobileTermsDrawer;
