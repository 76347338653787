import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { Row, Col } from '../../Cases/casePopOverStyles';
import {
	Card,
	CustomSwitch,
	DPad,
	Header,
	HeaderPanel,
	Knob,
	MainContainer,
	Title,
} from '../../../../views/CrmView/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ArrowDownIcon from '@worklist-2/ui/src/assets/img/arrowDown.svg';
import ArrowUpIcon from '@worklist-2/ui/src/assets/img/arrowUp.svg';
import Cases from './DetailTabs/Cases';
import Overview from './DetailTabs/Overview';
import Assets from './FullscreenCards/Assets';
import Users from './FullscreenCards/Users';
import Device from './FullscreenCards/Device/Device';
import License from './FullscreenCards/License';
import Organization from './FullscreenCards/Organization';
import OrganizationTab from './DetailTabs/Organization';
import {
	accountMapping,
	crmSearchScopes,
	useCRMDataLoader,
	useCRMSearchScope,
	useAuth,
	accountForm,
	useConfig,
} from '@worklist-2/core/src';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Box, Select, MenuItem, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import axios from 'axios';
import { useCrmContext } from '../../../../context/CRMContext';
import { defaultFeatures, TargetEntities } from '../../Cases/utils';

const AccountFullscreen = () => {
	const __config = useConfig();
	const replaceOp = 'replace';
	const numericRegex = /\d/;
	const { loggedInUser, authorized } = useAuth();
	const { toggleDetails, showDetails } = useCrmContext();
	const navigate = useNavigate();
	const params = useParams();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [contactData, setContactData] = useState([]);
	const [overViewData, setOverViewData] = useState({});
	const [editableIndex, setEditableIndex] = useState(null);
	const [errMsgs, setErrMsgs] = useState({});
	const [isValidForm, setIsValidForm] = useState(true);
	const { validateAccountForm, accountGlobalList } = useCRMSearchScope();
	const [watcherData, setWatcherData] = useState([]);
	const [watching, setWatching] = useState(false);
	const [hoveredField, setHoveredField] = useState(null);
	const [nextAccount, setNextAccount] = useState();
	const [previousAccount, setPreviousAccount] = useState();
	const [currentOrganizationID, setCurrentOrganizationID] = useState();
	const [orgId, setOrgId] = useState();
	const [dbClickOrganization, setDbClickOrganization] = useState(null);
	const [step, setStep] = useState(null);
	const [users, setUsers] = useState([]);
	const [devices, setDevices] = useState([]);
	const [organizationInfo, setOrganizationInfo] = useState(null);
	const [features, setFeatures] = useState([]);
	const [licenses, setLicenses] = useState([]);
	const [assets, setAssets] = useState([]);
	const resourceGroup = __config.resource_group;
	const entities = resourceGroup?.includes('team')
		? [{ name: resourceGroup.replaceAll('-', ' ').toUpperCase().replace('TEAM', 'Team'), value: 'resourceGroup' }]
		: TargetEntities[resourceGroup];

	const accountDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.accountApi,
	});

	useEffect(() => {
		if (authorized) {
			fetchData().catch(console.error);
		}

		getNextPreviousAccount();
	}, [authorized, params.id]);

	const getNextPreviousAccount = () => {
		let nextAcc = '';
		let previousAcc = '';
		const index = _.findIndex(accountGlobalList, { id: params.id });

		if (index !== -1) {
			if (index < accountGlobalList.length - 1) {
				nextAcc = accountGlobalList[index + 1].id;
			}

			if (index > 0) {
				previousAcc = accountGlobalList[index - 1].id;
			}
		}

		setNextAccount(nextAcc);
		setPreviousAccount(previousAcc);
	};

	const fetchData = async () => {
		const fnName = 'load';
		const bodyData = undefined;
		setLoading(true);

		try {
			await Promise.all([
				accountDataLoader[fnName]({ id: params.id }, undefined, bodyData).then(result => {
					const newData = result;
					setData(newData);
					setWatcherData(newData?.Watcher ?? []);
					setCurrentOrganizationID(newData?.Organization?.id);
					setOrgId(newData?.Organization?.id);
				}),
				axios.get(`${__config.data_sources.breeze}/Asset?account=${params.id}`).then(resp => {
					if (resp?.status === 200) {
						setAssets(resp.data?.entry);
					}
				}),
			]);
		} catch (e) {
			console.error(e);
		}

		setLoading(false);
	};

	const fetchOrganizationData = () => {
		Promise.all([
			axios
				.get(
					`${__config.data_sources.breeze}/PractitionerRoleOmegaAI/${params.id}?organization=${currentOrganizationID}`
				)
				.then(resp => {
					if (resp?.status === 200) {
						setUsers(resp.data?.entry || []);
					}
				})
				.catch(e => {
					setUsers([]);
				}),
			axios
				.get(
					`${__config.data_sources.breeze}/DeviceOmegaAI/${params.id}?managingorganization=${currentOrganizationID}`
				)
				.then(resp => {
					if (resp?.status === 200) {
						setDevices(resp.data?.entry || []);
					}
				})
				.catch(e => {
					setDevices([]);
				}),
			axios
				.get(`${__config.data_sources.breeze}/OrganizationOmegaAI/${params.id}/${currentOrganizationID}`)
				.then(resp => {
					if (resp?.status === 200) {
						setOrganizationInfo(resp.data);
					}
				})
				.catch(e => {
					setOrganizationInfo([]);
				}),
			axios
				.get(
					`${__config.data_sources.breeze}/OrganizationOmegaAI/${params.id}/${currentOrganizationID}/features`
				)
				.then(resp => {
					if (resp?.status === 200) {
						if (
							resp.data &&
							JSON.parse(resp.data).FeatureExtJson != null &&
							JSON.parse(resp.data).FeatureExtJson.length > 0
						) {
							setFeatures(JSON.parse(resp.data)?.FeatureExtJson);
						} else {
							setFeatures(defaultFeatures);
						}
					}
				})
				.catch(e => {
					setFeatures([]);
				}),
			axios
				.get(
					`${__config.data_sources.breeze}/OrganizationLicenseOmegaAI/${params.id}?organization=${currentOrganizationID}`
				)
				.then(resp => {
					if (resp?.status === 200) {
						setLicenses(resp.data?.entry || []);
					}
				})
				.catch(e => {
					setLicenses([]);
				}),
		]);
	};

	useEffect(() => {
		if (currentOrganizationID) {
			resetOrganizationRelatedData();
			fetchOrganizationData();
		}
	}, [currentOrganizationID]);

	const resetOrganizationRelatedData = () => {
		setStep(null);
		setUsers([]);
		setDevices([]);
		setOrganizationInfo(null);
		setFeatures([]);
		setLicenses([]);
	};

	useEffect(() => {
		if (!!dbClickOrganization && !step) {
			setStep('organization');
		}
	}, [dbClickOrganization]);

	useEffect(() => {
		if (step) {
			toggleDetails({
				data: {
					accountData: data,
					fetchAccountData: fetchData,
					organizationData: dbClickOrganization,
					setOrganizationData: setDbClickOrganization,
					step,
					setStep,
				},
				tag: 'addOrganization',
			});
		} else {
			!!toggleDetails && toggleDetails();
		}
	}, [step]);

	useEffect(() => () => toggleDetails(), []);

	useEffect(() => {
		if (orgId) {
			const getNewDevices = () => {
				axios
					.get(`${__config.data_sources.breeze}/DeviceOmegaAI/${params.id}?managingorganization=${orgId}`)
					.then(resp => {
						if (resp?.status === 200) {
							setDevices(resp.data?.entry || []);
						}
					})
					.catch(e => {
						setDevices([]);
					});
			};

			setTimeout(() => getNewDevices(), 1000);
		}
	}, [showDetails?.data?.sidebarMode?.mode]);

	useEffect(() => {
		if (data) {
			setContactData(
				_.map(data?.RelatedContact, contact => ({
					name: contact.FullName,
					jobTitle: contact.JobTitle,
					phone: contact.CellPhone,
					email: contact.Email,
				}))
			);
		}
	}, [data]);

	const onChangeData = async (e, value, tab) => {
		const targetId = e.target.id ? e.target.id : '';
		const targetParentId = e.target.parentElement?.id ? e.target.parentElement.id : '';

		if (targetId.split('_')[0] === 'closeEditButton' || targetParentId.split('_')[0] === 'closeEditButton') {
			const accountData = overViewData;
			if (value == 'billing_address' || value == 'shipping_address') {
				delete accountData[accountMapping[`${value}_address_line_1`]];
				delete accountData[accountMapping[`${value}_address_line_2`]];
				delete accountData[accountMapping[`${value}_city`]];
				delete accountData[accountMapping[`${value}_province`]];
				delete accountData[accountMapping[`${value}_country`]];
				delete accountData[accountMapping[`${value}_postal_code`]];
			} else {
				delete accountData[accountMapping[value]];
			}
			setOverViewData(accountData);
			setErrMsgs({});
			setEditableIndex(null);
		} else if (tab === 'overview') {
			handleOverViewTabChange(e.target.name, e.target.value);
		} else if (tab === 'contacts') {
			handleContactTabChange(value);
		}
	};

	const handleOverViewTabChange = (name, value) => {
		const accountData = overViewData;
		accountData[accountMapping[name]] = value;
		setOverViewData(accountData);
	};

	const handleContactTabChange = value => {
		let tempData = contactData;
		if (value.Index == undefined) {
			tempData.push(value);
		} else if (value.Deleted) {
			tempData = _.remove(tempData, currentObject => !currentObject.Deleted);
		}
		setContactData(tempData);
	};

	const handleSubmit = async event => {
		event.preventDefault();
		let accountJson = {};

		if (!validateAccountForm(event.target, overViewData, setErrMsgs, setIsValidForm, true)) {
			return;
		}

		const submitField = document.activeElement.id.replace('submitButton_', '');
		const fieldName = [];
		const fieldValue = [];
		fieldName.push(accountMapping[submitField]);

		switch (document.activeElement.value) {
			case 'commonSubmit':
				accountJson = buildAccountJson(event.target, data, true, false, false);
				break;
			case 'overviewSubmit':
				accountJson = buildAccountJson(event.target, data, false, true, false);
				if (submitField === 'parent_account') {
					fieldName.splice(0, 1);
					fieldName.push('ParentAccount', 'ParentAccountId');
					fieldValue.push(accountJson.ParentAccount, accountJson.ParentAccountId);
				} else if (submitField === 'ownership') {
					fieldName.splice(0, 1);
					fieldName.push('Ownership', 'OwnershipId');
					fieldValue.push(accountJson.Ownership, accountJson.OwnershipId);
				} else if (submitField == 'billing_address' || submitField == 'shipping_address') {
					fieldName.splice(0, 1);
					fieldName.push(
						accountMapping[`${submitField}_address_line_1`],
						accountMapping[`${submitField}_address_line_2`],
						accountMapping[`${submitField}_city`],
						accountMapping[`${submitField}_province`],
						accountMapping[`${submitField}_country`],
						accountMapping[`${submitField}_postal_code`]
					);
					fieldValue.push(
						accountJson[accountMapping[`${submitField}_address_line_1`]],
						accountJson[accountMapping[`${submitField}_address_line_2`]],
						accountJson[accountMapping[`${submitField}_city`]],
						accountJson[accountMapping[`${submitField}_province`]],
						accountJson[accountMapping[`${submitField}_country`]],
						accountJson[accountMapping[`${submitField}_postal_code`]]
					);
				}
				break;
			case 'contactSubmit':
				accountJson = buildAccountJson(event.target, data, false, false, true);
				fieldName.splice(0, 1);
				fieldName.push('RelatedContact');
				break;
			case 'watcherSubmit':
				const tempData = [...watcherData];
				if (isWatching()) {
					_.remove(tempData, watcher => watcher.UserId === loggedInUser.id);
				} else {
					tempData.push({
						UserName: loggedInUser.display,
						UserId: loggedInUser.id,
					});
				}
				setWatcherData(tempData);
				accountJson = buildAccountJson(event.target, data, false, false, false, tempData);
				break;
		}

		if (fieldValue.length === 0) {
			fieldValue.push(accountJson[fieldName]);
		}

		const result = await accountDataLoader.patch(
			data.AccountId ? data.AccountId : data.id,
			fieldName,
			fieldValue,
			replaceOp
		);
		if (result.status == 409) {
			setErrMsgs({ clientId: 'Already exist' });
			setIsValidForm(false);
			return;
		}

		setEditableIndex(null);
	};

	const buildAccountJson = (eventData, accountData, isCommonSubmit, isOverviewSubmit, isContactSubmit, watchers) => {
		const accountJson = accountData;
		accountJson.ResourceType = 'Account';
		accountJson.Watcher = watchers ?? watcherData;

		if (isCommonSubmit) {
			accountJson.ClientId = eventData.clientId?.value?.toUpperCase() ?? accountJson.ClientId;
			accountJson.AccountName = eventData.accountName?.value ?? accountJson.AccountName;
			accountJson.Phone = eventData.phone?.value ?? accountJson.Phone;
			accountJson.ReferenceStatus = eventData.referenceStatus?.value ?? accountJson.ReferenceStatus;
			accountJson.AccountType = eventData.accountType?.value ?? accountJson.AccountType;
			accountJson.TargetEntity = eventData.targetEntity?.value ?? accountJson.TargetEntity;
		}

		if (isOverviewSubmit) {
			//Data need handle before post
			for (const item in overViewData) {
				accountJson[item] = overViewData[item];
			}
		}

		if (isContactSubmit) {
			accountJson.RelatedContact = _.map(contactData, contact => ({
				FullName: contact.name,
				JobTitle: contact.jobTitle,
				CellPhone: contact.phone,
				Email: contact.email,
			}));
		}

		return accountJson;
	};

	useEffect(() => {
		setWatching(isWatching());
	}, [watcherData]);

	const isWatching = () => _.some(watcherData, watcher => watcher.UserId === loggedInUser.id);

	const displayValue = item => {
		if (!data[item.valueName]) {
			return item.fieldName === 'referenceStatus' ? 'N/A' : '-';
		}

		return item.fieldName === 'targetEntity'
			? entities.find(targetEntity => targetEntity.value === data[item.valueName])?.name || '-'
			: data[item.valueName];
	};

	return !loading ? (
		data ? (
			<form onSubmit={handleSubmit}>
				<Header>
					<Title>
						<ViewListOutlinedIcon sx={{ fontSize: 29, mr: '12px' }} />
						<span>Accounts</span>
					</Title>
					<CustomSwitch>
						<Knob style={{ zIndex: 1 }} onClick={() => navigate('/')}>
							<HomeOutlinedIcon />
						</Knob>
						<Knob style={{ marginLeft: '-16px' }} onClick={() => navigate('/account')}>
							<span>Accounts</span>
						</Knob>
						<label>{data?.AccountName}</label>
					</CustomSwitch>
				</Header>
				<Box
					sx={{
						overflow: 'auto',
						position: 'relative',
						zIndex: 0,
						height: 'calc(100vh - 144px)',
						maxHeight: '100vh',
						mb: 10,
						pt: 5,
						flex: 1,
						width: '100%',
						flexDirection: 'row',
						paddingTop: 0,
						'&::-webkit-scrollbar-thumb': {
							background: '#C4C4C4 !important',
							borderRadius: '3px',
						},
						'&::-webkit-scrollbar-track': {
							background: '#FFF !important',
						},
					}}
				>
					<HeaderPanel className="invisible-scrollbar" showDetails={showDetails}>
						<div className="left">
							<Select
								disabled
								IconComponent=""
								defaultValue={data?.Status}
								name="status"
								sx={{
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
									height: '20px',
									width: '5px',
									justifyContent: 'center',
									padding: '7px 2px 2px 2px',
								}}
							>
								<MenuItem value="busy">
									<Brightness1Icon
										sx={{
											fontSize: 'medium',
											color: 'red',
										}}
									/>
								</MenuItem>
								<MenuItem value="away">
									<Brightness1Icon
										sx={{
											fontSize: 'medium',
											color: 'yellow',
										}}
									/>
								</MenuItem>
								<MenuItem value="active">
									<Brightness1Icon
										sx={{
											fontSize: 'medium',
											color: '#3BE775',
										}}
									/>
								</MenuItem>
							</Select>
							{editableIndex === 'accountName' ? (
								<div className="row" style={{ marginLeft: '15px' }}>
									<TextField
										InputProps={{
											endAdornment: editableIndex === 'accountName' && (
												<IconButton
													id="submitButton_accountName"
													sx={{
														paddingRight: 0,
													}}
													type="submit"
													value="commonSubmit"
												>
													<CheckCircleIcon
														sx={{
															pointerEvents: 'none',
															color: '#0047ff',
															fontSize: '22px',
															marginLeft: 'auto',
														}}
													/>
												</IconButton>
											),
										}}
										autoFocus={editableIndex === 'accountName'}
										defaultValue={data.AccountName}
										error={!!errMsgs.accountName}
										helperText={errMsgs.accountName}
										name="accountName"
										placeholder="Enter a name for this account"
										sx={{
											width: '375px',
											'& input': {
												marginRight: '0px',
											},
										}}
										variant="standard"
									/>
									{editableIndex === 'accountName' && (
										<IconButton
											id="closeEditButton_accountName"
											sx={{
												padding: '7px 2px 2px 2px',
												marginLeft: '2px',
											}}
											type="button"
										>
											<CloseIcon
												id="closeEditButton_accountName"
												sx={{
													color: '#0047ff',
												}}
												onClick={e => {
													setErrMsgs({});
													setEditableIndex(null);
												}}
											/>
										</IconButton>
									)}
									<span style={{ color: 'red' }}>&nbsp;*</span>
								</div>
							) : (
								<div className="row" style={{ marginLeft: '15px' }}>
									<span
										style={{
											maxWidth: '80%',
											cursor: loggedInUser.permission?.Account.Edit ? 'pointer' : '',
										}}
										onClick={() => {
											if (loggedInUser.permission?.Account.Edit) {
												setEditableIndex('accountName');
											}
										}}
										onMouseEnter={() => setHoveredField('accountName')}
									>
										{data.AccountName || '-'}
									</span>
									{hoveredField === 'accountName' && !!loggedInUser.permission.Account.Edit && (
										<IconButton
											id={'closeEditButton_Edit_' + 'accountName'}
											sx={{
												p: 0,
												marginLeft: '5px',
												padding: '0px 0px 2px 0px',
											}}
											type="button"
											onClick={e => {
												setEditableIndex('accountName');
											}}
										>
											<EditOutlinedIcon
												sx={{
													color: 'rgba(0, 0, 0, 0.6)',
													fontSize: '20px',
													marginLeft: 'auto',
												}}
											/>
										</IconButton>
									)}
									<span style={{ color: 'red' }}>&nbsp;*</span>
								</div>
							)}
							<DPad>
								<Icon
									component={ArrowUpIcon}
									fill={previousAccount ? '#4D79EA' : 'rgba(0, 0, 0, 0.26)'}
									sx={{
										width: '12px',
										height: '14px',
										cursor: previousAccount ? 'pointer' : 'context-menu',
									}}
									onClick={() => {
										if (previousAccount) {
											resetOrganizationRelatedData();
											navigate(`/account/${previousAccount}`);
										}
									}}
								/>
								<Icon
									component={ArrowDownIcon}
									fill={nextAccount ? '#4D79EA' : 'rgba(0, 0, 0, 0.26)'}
									sx={{
										width: '12px',
										height: '14px',
										cursor: nextAccount ? 'pointer' : 'context-menu',
									}}
									onClick={() => {
										if (nextAccount) {
											resetOrganizationRelatedData();
											navigate(`/account/${nextAccount}`);
										}
									}}
								/>
							</DPad>
						</div>
						<div className="center" style={{ flex: 2 }}>
							<Row>
								{accountForm.commonTextFields.map((item, i) =>
									editableIndex === item.fieldName ? (
										<Col
											style={{
												flex: 'none',
												minWidth: '100px',
											}}
										>
											<label>
												{item.labelName}
												{item.fieldName === 'clientId' ? (
													<span style={{ color: 'red' }}>&nbsp;*</span>
												) : (
													''
												)}
											</label>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													marginTop: '2px',
													fontSize: '14px',
												}}
											>
												{item.fieldName === 'targetEntity' ? (
													<>
														<Select
															defaultValue={data[item.valueName]}
															id={item.fieldName}
															name={item.fieldName}
															style={{
																border: 'none',
																fontFamily: 'Inter',
															}}
															sx={{
																'& legend': {
																	display: 'none',
																},
																'& fieldset': {
																	top: 0,
																},
																height: '40px',
																width: '156px',
																fontSize: '12px',
																fontStretch: 'normal',
																fontStyle: 'normal',
																flex: '1',
																borderRadius: 3,
															}}
														>
															{entities.map(selectItem => (
																<MenuItem
																	key={selectItem.value}
																	value={selectItem.value}
																>
																	{selectItem.name}
																</MenuItem>
															))}
														</Select>
														<IconButton
															data-testid={`submitButton_${item.fieldName}`}
															id={`submitButton_${item.fieldName}`}
															sx={{
																paddingRight: 0,
															}}
															type="submit"
															value="commonSubmit"
														>
															<CheckCircleIcon
																sx={{
																	pointerEvents: 'none',
																	color: '#0047ff',
																	fontSize: '22px',
																	marginLeft: 'auto',
																}}
															/>
														</IconButton>
													</>
												) : (
													<TextField
														InputProps={{
															endAdornment: (
																<IconButton
																	data-testid={`submitButton_${item.fieldName}`}
																	id={`submitButton_${item.fieldName}`}
																	sx={{
																		paddingRight: 0,
																	}}
																	type="submit"
																	value="commonSubmit"
																>
																	<CheckCircleIcon
																		sx={{
																			pointerEvents: 'none',
																			color: '#0047ff',
																			fontSize: '22px',
																			marginLeft: 'auto',
																		}}
																	/>
																</IconButton>
															),
														}}
														autoFocus={editableIndex === item.fieldName}
														data-testid={item.fieldName}
														defaultValue={data[item.valueName]}
														error={!!errMsgs[item.fieldName]}
														helperText={errMsgs[item.fieldName]}
														name={item.fieldName}
														size="small"
														sx={{
															'.MuiFormHelperText-root': {
																fontSize: '10px',
															},
															'.MuiOutlinedInput-root': {
																paddingRight: '5px',
																height: '35px',
																borderRadius: 2,
															},
															height: '30px',
															'& legend': {
																display: 'none',
															},
															'& fieldset': {
																top: 0,
															},
															flex: '1',
															width: '200px',
														}}
														variant="outlined"
														onKeyPress={e => {
															if (
																item.fieldName === 'phone' &&
																!numericRegex.test(e.key)
															) {
																e.preventDefault();
															}
														}}
													/>
												)}
												<IconButton
													data-testid={`closeEditButton_${item.fieldName}`}
													id={`closeEditButton_${item.fieldName}`}
													sx={{
														padding: '5px 1px 1px 1px',
														marginLeft: '1px',
													}}
													type="button"
													onClick={e => {
														setErrMsgs({});
														setEditableIndex(null);
													}}
												>
													<CloseIcon
														id={`closeEditButton_${item.fieldName}`}
														sx={{
															color: '#0047ff',
														}}
													/>
												</IconButton>
											</Box>
										</Col>
									) : (
										<Col
											style={{
												flex: 'none',
												minWidth: '100px',
											}}
										>
											<label>
												{item.labelName}
												{item.fieldName === 'clientId' ? (
													<span style={{ color: 'red' }}>&nbsp;*</span>
												) : (
													''
												)}
											</label>
											<Box>
												<span
													style={{
														display: 'inline-block',
														maxWidth: '200px',
														wordWrap: 'break-word',
														cursor:
															!!loggedInUser.permission?.Account.Edit &&
															!(item.fieldName === 'clientId' && !!data?.Organization?.id)
																? 'pointer'
																: '',
													}}
													onClick={() => {
														if (
															!!loggedInUser.permission?.Account.Edit &&
															!(item.fieldName === 'clientId' && !!data?.Organization?.id)
														) {
															setEditableIndex(item.fieldName);
														}
													}}
													onMouseEnter={() => setHoveredField(item.fieldName)}
												>
													{displayValue(item)}
												</span>
												{hoveredField === item.fieldName &&
													!!loggedInUser.permission.Account.Edit &&
													!(item.fieldName === 'clientId' && !!data?.Organization?.id) && (
														<IconButton
															data-testid={`closeEditButton_Edit_${item.fieldName}`}
															id={`closeEditButton_Edit_${item.fieldName}`}
															sx={{ p: 0, marginLeft: '5px' }}
															type="button"
															onClick={e => {
																setEditableIndex(item.fieldName);
															}}
														>
															<EditOutlinedIcon
																sx={{
																	color: 'rgba(0, 0, 0, 0.6)',
																	fontSize: '16px',
																	marginLeft: 'auto',
																}}
															/>
														</IconButton>
													)}
											</Box>
										</Col>
									)
								)}
							</Row>
						</div>
						<div className="divider" />
						<div
							className="right"
							style={{
								margin: '0 39px',
							}}
						>
							<IconButton
								id="submitButton_watcher"
								sx={{ fontSize: 20, color: '#4D79EA' }}
								type="submit"
								value="watcherSubmit"
							>
								{watching ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
							</IconButton>
						</div>
					</HeaderPanel>
					<MainContainer showDetails={showDetails}>
						<Row
							style={{
								flex: 1,
								margin: 0,
								alignItems: 'flex-start',
							}}
						>
							{/* left */}
							<Col style={{ flex: 3, margin: 0 }}>
								<Card sx={{ marginLeft: 0 }}>
									<Overview
										expanded
										data={data}
										editableIndex={editableIndex}
										errorMsgs={errMsgs}
										hoveredField={hoveredField}
										isValidForm={isValidForm}
										setEditableIndex={setEditableIndex}
										setHoveredField={setHoveredField}
										onChangeData={onChangeData}
									/>
								</Card>
								<Card sx={{ marginLeft: 0 }}>
									<Organization
										accountData={data}
										canEdit={!!loggedInUser.permission.Account.Edit}
										currentOrganizationID={currentOrganizationID}
										featuresData={features}
										informationData={organizationInfo}
										initialStep={step}
										setDbClickOrganization={setDbClickOrganization}
										setFeatures={setFeatures}
										setStepCustom={setStep}
									/>
								</Card>
							</Col>
							{/* right */}
							<Col
								style={{
									flex: 1,
									minWidth: '622px',
									margin: 0,
								}}
							>
								<Card sx={{ marginRight: 0, maxHeight: '500px', overflowY: 'auto' }}>
									<OrganizationTab
										accountData={data}
										fetchAccountData={fetchData}
										listMaxHeight={300}
										setClickedOrganization={setDbClickOrganization}
										setCurrentOrganizationID={setCurrentOrganizationID}
										setStepCustom={setStep}
									/>
								</Card>
								<Card sx={{ marginRight: 0, maxHeight: '500px', overflowY: 'auto' }}>
									<Device
										accountData={data}
										data={devices}
										setDbClickOrganization={setDbClickOrganization}
										setStep={setStep}
										step={step}
									/>
								</Card>
								<Card sx={{ marginRight: 0 }}>
									<Users data={users} />
								</Card>
								<Card sx={{ marginRight: 0 }}>
									<Cases expanded fullScreen accountData={data} />
								</Card>
								<Card sx={{ marginRight: 0 }}>
									<Assets data={assets} orgId={orgId} />
								</Card>
								<Card sx={{ marginRight: 0 }}>
									<License data={licenses} />
								</Card>
							</Col>
						</Row>
					</MainContainer>
				</Box>
			</form>
		) : (
			<Box
				sx={{
					height: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					paddingBottom: '100px',
					boxSizing: 'border-box',
				}}
			>
				<Typography
					sx={{
						textAlign: 'center',
						fontSie: '16px',
						marginBottom: '8px',
						color: 'black',
					}}
				>
					Not Found
				</Typography>
			</Box>
		)
	) : (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				paddingBottom: '100px',
				boxSizing: 'border-box',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontSie: '16px',
					marginBottom: '8px',
					color: 'black',
				}}
			>
				<CircularProgress
					className="progressBar"
					size={22}
					sx={{
						color: '#03dac5',
						marginRight: '16px',
					}}
				/>
				Loading...
			</Typography>
		</Box>
	);
};

export default AccountFullscreen;
