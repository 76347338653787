// @flow
//core
import React, { useState, useEffect, useCallback, useContext, type Node } from 'react';
//components
import FormFieldDefault from './FormFieldDefault';
import { CustomChipsWithArrowButtons } from '@rs-ui/components/CustomChipsWithArrowButtons';
//library
import debounce from 'lodash/debounce';
import { Autocomplete, Box, Avatar, Typography, Paper, Button, Divider, CircularProgress } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//utils
import FormContext from '@rs-components/FormNew/formContexts/FormContext';
import { getUserFullName } from '@worklist-2/core/src';
import { getAutocompleteClearAndPopupTooltips } from '@rs-ui/helpers/tooltipUtils';

interface IFormAPIAutocompleteMultipleVariantProps {
	form: any;
	onSearch: (newInputValue: any) => Promise<any[]>;
	onChange: (newInputValue: any) => Promise<void>;
	name: string;
	buttonLabel: string;
	renderChipLabel: (option: any) => string;
	styles: Record<string, any>;
	drawerOpen: boolean;
	onButtonClick: () => void;
	buttonIcon: any;
	sx: Record<string, any>;
	props: any;
}

const renderOptions = (props, option, styles) => (
	<li {...props} key={option?.id}>
		{option?.avatarPath ? (
			<Box sx={styles.rootAvatar}>
				<Avatar src={option.avatarPath} sx={styles.avatarPath} />
			</Box>
		) : (
			<AccountCircleIcon sx={{ pr: 1.75 }} />
		)}
		{option?.name?.[0] ? (
			<Box sx={styles.renderLabel}>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Typography
						noWrap
						data-testid={`consulting-physician-${getUserFullName(option?.name?.[0])}`}
						sx={{
							fontSize: '14px',
							fontWeight: 400,
							color: 'rgba(255,255,255,0.7)',
							maxWidth: '100%',
						}}
					>
						{getUserFullName(option?.name?.[0])}
					</Typography>

					<Typography noWrap data-testid="consulting-physician-subtitle-org" sx={styles.nameBox}>
						{option?.extension?.find(item => item?.url?.includes('organization'))?.valueReference
							?.display ? (
							<Typography sx={styles.nameBoxWithSubTitle}>
								{`${
									option?.extension?.find(item => item?.url?.includes('organization'))?.valueReference
										?.display
								}`}
							</Typography>
						) : null}
					</Typography>
				</Box>
			</Box>
		) : (
			''
		)}
	</li>
);

export const FormAPIAutocompleteMultipleVariant = ({
	buttonIcon,
	buttonLabel,
	drawerOpen,
	form,
	name,
	props,
	styles,
	sx,
	onButtonClick,
	onChange,
	onSearch,
	renderChipLabel,
	textFieldProps,
}: IFormAPIAutocompleteMultipleVariantProps): Node => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [expand, setExpand] = useState(false);

	const { readOnly } = useContext(FormContext);
	const { control } = form;

	const [isLoading, setIsLoading] = useState(false);
	const [hideClearIcon, setHideClearIcon] = useState(false);

	const { t } = useTranslation('formapiautocompletevariant');

	const handleInputChange = async (event, newInputValue, reason) => {
		const fetchNewOptions = async () => {
			if (newInputValue) {
				hideClearIcon && setHideClearIcon(false);
				setIsLoading(true);
				const res = await onSearch(newInputValue);
				setIsLoading(false);
				if (res) {
					setOptions(res);
				}
			} else {
				setOptions([]);

				!hideClearIcon && setHideClearIcon(true);
			}
		};

		fetchNewOptions();
	};
	const watchConsultingPhysician = form.watch('consultingPhysician');

	const debounceOnInputChange = useCallback(
		debounce((event, newInputValue, reason) => handleInputChange(event, newInputValue, reason), 500),
		[onSearch]
	);

	useEffect(() => {
		if (watchConsultingPhysician?.length <= 1) {
			setExpand(false);
		}
	}, [watchConsultingPhysician]);

	useEffect(() => {
		setExpand(false);
	}, [drawerOpen]);

	const renderInput = params => (
		<Box sx={{ position: 'relative' }}>
			<FormFieldDefault
				formHook={form}
				name={name}
				props={{
					...params,
					...textFieldProps,
					InputProps: {
						...params.InputProps,
						...textFieldProps,
						startAdornment: (
							<>
								<AccountCircleIcon sx={{ pr: 1.75 }} />
								{params.InputProps.startAdornment}
							</>
						),
						endAdornment: (
							<>
								{isLoading && <CircularProgress color="inherit" size={20} />}
								{params?.InputProps?.endAdornment}
							</>
						),
						...textFieldProps?.InputProps,
					},
				}}
			/>
		</Box>
	);

	const renderButtonPaper = ({ containerProps, children }) => (
		<Paper sx={SX.renderButtonPaperStyles} {...containerProps}>
			{onButtonClick && (
				<>
					<Button
						startIcon={buttonIcon || ''}
						sx={{
							'& .MuiButton-startIcon': {},
							justifyContent: 'left',
							height: '36px',
						}}
						onMouseDown={onButtonClick}
					>
						{buttonLabel}
					</Button>
					<Divider />
					{children}
				</>
			)}
		</Paper>
	);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<Autocomplete
					{...field}
					{...props}
					filterSelectedOptions
					multiple
					PaperComponent={buttonLabel && renderButtonPaper}
					clearText={null}
					closeText={t('closeText')}
					disableClearable={hideClearIcon}
					disabled={readOnly}
					getOptionLabel={option => {
						if (
							option?.extension?.find(item => item?.url?.includes('organization'))?.valueReference
								?.display
						) {
							return renderChipLabel(option) || '';
						}
						return option?.name?.[0] ? `${getUserFullName(option?.name?.[0])}` : '';
					}}
					getOptionSelected={(option, value) => option?.id === value?.id}
					isOptionEqualToValue={(option, value) => !value || option?.id === value?.id}
					limitTags={1}
					loading={isLoading}
					loadingText={t('loading')}
					noOptionsText={t('noOptions')}
					open={open}
					openText={null}
					options={!Array.isArray(options) ? [] : options}
					renderInput={renderInput}
					renderOption={(renderProps, option) => renderOptions(renderProps, option, styles)}
					renderTags={(tagValue, getTagProps) => (
						<CustomChipsWithArrowButtons
							expand={expand}
							getTagProps={getTagProps}
							renderChipLabel={renderChipLabel}
							setExpand={setExpand}
							tagValue={tagValue}
						/>
					)}
					rules={{
						validate: data => {
							if (data.length === 0) return false;
						},
					}}
					sx={{
						...sx,
						...getAutocompleteClearAndPopupTooltips({
							clearText: t('clearText'),
							openText: open ? '' : t('openText'),
						}),
						'& .MuiOutlinedInput-root': {
							paddingRight: '49px!important',
						},
					}}
					value={field.value || []}
					onChange={(event, newInputValue, reason) => {
						field.onChange(newInputValue);
						onChange?.(newInputValue);
					}}
					onClose={() => setOpen(false)}
					onInputChange={(event, newInputValue, reason) =>
						debounceOnInputChange(event, newInputValue, reason)
					}
					onOpen={() => setOpen(props?.notOpen ? !props?.notOpen : true)}
				/>
			)}
		/>
	);
};

const SX = {
	renderButtonPaperStyles: {
		background: '#393939',
		display: 'flex',
		flexDirection: 'column',
		border: '0px',
		'.MuiAutocomplete-noOptions': {
			fontFamily: 'fontFamily',
		},
		'.MuiAutocomplete-loading': {
			fontFamily: 'fontFamily',
		},
		'.MuiAutocomplete-option': {
			fontFamily: 'fontFamily',
		},
	},
};
