import React from 'react';
import Box from '@mui/material/Box';
import { LanguageModalContent } from '../../LanguageSelect/LanguageModal';

const MobileLanguageDrawer = () => (
	<Box>
		<LanguageModalContent />
	</Box>
);

export default MobileLanguageDrawer;
